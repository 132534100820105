@import 'src/styles/variables';
@import 'src/styles/mixins';

.sidebar {
  @include clearfix;

  padding: 0;
  margin-top: 5rem;

  @media (min-width: $two-up-breakpoint) {
    padding: $gutter-width-y $gutter-width-x * 0.5;
    margin-top: 3rem;
  }

  @media (min-width: $sidebar-breakpoint) {
    width: $min-box-width;
    padding: 0;
    margin: 0;
  }

  @media (min-width: 87.5rem) {
    width: 20rem;
  }

  @media (min-width: 112.5rem) {
    width: 25rem;
  }
}
