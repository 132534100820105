@use 'sass:math';

@import 'src/styles/variables';
@import 'src/styles/mixins';

.gallery {
  margin-top: 2em;
  margin-bottom: 2em;
  overflow: hidden;
  box-sizing: border-box;

  .gallery-inner {
    margin-left: -1px;
    margin-right: -1px;
  }

  .gallery-item {
    display: inline-block;
    padding: 0 1px 1px;
    margin: 0;
    box-sizing: border-box;

    .gallery-icon {
      display: inline-block;
      width: 100%;

      img {
        width: 100%;
        height: auto;
        vertical-align: sub;
      }
    }
  }

  &:global(.gallery-columns-2) {
    &:global(.gallery-size-blog-image-tall) {
      position: relative;

      .gallery-item {
        &,
        .gallery-icon,
        .gallery-icon img {
          display: block;
        }

        &:nth-child(2) {
          position: absolute;
          top: 0;
          right: 0;
        }

        &:last-child {
          margin-top: 2px;
        }
      }
    }

    .gallery-item {
      width: 50%;
    }
  }

  &:global(.gallery-columns-3) .gallery-item {
    width: math.div(1, 3) * 100%;
  }

  &:global(.gallery-columns-4) .gallery-item {
    width: 25%;
  }

  &:global(.gallery-columns-5) .gallery-item {
    width: 20%;
  }

  @media screen and (max-width: 751px) {
    @include full-width-image;
  }
}
