@import 'src/styles/variables';
@import 'src/styles/mixins';

$padding-left: 1.25rem;

.box {
  > :global(.box) {
    background-color: lighten($color-light-cream, 7.5%);
    padding-left: $padding-left;
  }
}

.image {
  margin: (-$sidebar-box-padding-y) (-$sidebar-box-padding-x);
  margin-left: -$padding-left;
  margin-bottom: 1rem;
  height: 10rem;
  overflow: hidden;

  img {
    position: relative;
    width: 100%;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid $color-light-cream;
    border-left: 0;

    html:global(.has-object-fit) & {
      height: 100%;
      object-fit: cover;
      object-position: center;
      transform: none;
      top: inherit;
    }
  }
}

.posts {
  margin-top: 1rem;

  > ul {
    @include list-unstyled;
    margin: 0;

    li {
      line-height: 1.3;
      margin: 0.6rem 0;
      letter-spacing: normal;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        $hover-color: darken($link-color, 5%);
        position: relative;
        transition: color 300ms ease;

        &:hover {
          text-decoration: none;
          color: $hover-color;
        }

        &:global(.active) {
          pointer-events: none;
          color: transparentize($hover-color, 0.2);

          &::before {
            content: '▶';
            font-size: 8px;
            position: absolute;
            left: -1.5em;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

.next-prev {
  margin-top: 1.5rem;

  .next-prev-item {
    padding: 0.25rem 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    a {
      color: $link-color;
    }
  }
}

.view {
  display: block;
  text-align: center;
  font-size: 0.8rem;
  background-color: $color-light-cream;
  margin: (-$sidebar-box-padding-y) (-$sidebar-box-padding-x);
  margin-left: -$padding-left;
  margin-top: 1rem;
  padding: 0.5rem 0.75rem;
  transition: background-color 300ms ease;
  border-top: 1px solid darken($color-light-cream, 1%);
  color: $color-brown;

  &:hover {
    text-decoration: none;
    background-color: darken($color-light-cream, 5%);
    border-top: 1px solid darken($color-light-cream, 6%);
  }
}
