@import 'src/styles/variables';

$alert-padding: 10px;

@mixin alert-variant($background, $border, $text-color) {
  color: $text-color;
  background-color: $background;
  border-color: $border;

  hr {
    border-top-color: darken($border, 5%);
  }

  :global(.alert-link) {
    color: darken($text-color, 10%);
  }
}

.alert {
  padding: $alert-padding;
  border: 1px solid transparent;
  border-radius: 5px;

  &:global(.alert-dismissible) {
    padding-right: ($alert-padding + 20);

    .close {
      position: relative;
      top: -2px;
      right: -21px;
      float: right;
      padding: 0;
      font-size: 1.5em;
      font-weight: bold;
      line-height: 1;
      color: inherit;
      cursor: pointer;
      background: transparent;
      border: 0;
      opacity: 0.2;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  &:global(.alert-success) {
    @include alert-variant(
      $state-success-bg,
      $state-success-border,
      $state-success-text
    );
  }

  &:global(.alert-info) {
    @include alert-variant(
      $state-info-bg,
      $state-info-border,
      $state-info-text
    );
  }

  &:global(.alert-warning) {
    @include alert-variant(
      $state-warning-bg,
      $state-warning-border,
      $state-warning-text
    );
  }

  &:global(.alert-danger) {
    @include alert-variant(
      $state-danger-bg,
      $state-danger-border,
      $state-danger-text
    );
  }
}

@import 'alert';
