@import 'src/styles/variables';

.post-trips,
.spacer {
  margin-bottom: $gutter-width-y;

  @media (min-width: $sidebar-breakpoint) {
    margin-left: -$gutter-width-x;
  }
}

.post-trips {
  :global(.sidebar-box-wrapper) + & {
    margin-top: $gutter-width-y;
  }

  &:global(.fixed) {
    @media (min-width: $container-breakpoint) {
      position: fixed;
      top: calc(#{$nav-bar-height} + 1px);
    }

    // @media (min-width: ($container-max-width / $container-width-base)) {
    //   // maximum container width
    // }

    @media (min-width: $sidebar-breakpoint) {
      width: $min-box-width + $gutter-width-x;
    }

    @media (min-width: 87.5rem) {
      width: 20rem + $gutter-width-x;
    }

    @media (min-width: 112.5rem) {
      width: 25rem + $gutter-width-x;
    }
  }

  &:global(.fixed-bottom) {
    @media (min-width: $container-breakpoint) {
      position: absolute;
      bottom: 0;
      margin-bottom: 0;
    }
  }
}
