@use 'sass:math';

@import 'src/styles/variables';
@import 'src/styles/mixins';

.entry-header {
  margin: 1.3333333333em 0;
  letter-spacing: 0;
}

.entry-title {
  border-bottom: 1px solid $color-light-blue;
  font-variant: small-caps;
}

.entry-date {
  padding: 0.2em 0;
  color: $color-gray;
}

// :global(.entry-content) {
//   font-variant-numeric: oldstyle-nums;
//   font-variant-ligatures: common-ligatures;
//   font-kerning: normal;
// }

.footer-content {
}

article {
  &.post {
    @include content-box;
    padding: $post-padding-xs-y $post-padding-xs-x;

    @media (min-width: $breakpoint-xs) {
      padding: $post-padding-y $post-padding-x;
    }

    .places {
      position: relative;

      &,
      a {
        color: $color-gray;
      }

      &::before {
        content: ' ';
        display: block;
        position: absolute;
        bottom: 100%;
        width: percentage(math.div(1, 3));
        border-bottom: 1px solid #ddd;
        margin-bottom: 0.25rem;
      }

      ul {
        @include list-unstyled;
        display: inline-block;
        margin: 0;
        margin-left: 0.5em;

        li {
          display: inline-block;
          margin-right: 0.5em;

          &::after {
            content: '; ';
          }

          &:last-child {
            &::after {
              content: none;
            }
          }
        }
      }
    }

    &:global(.summary) {
      $padding-x: 1rem;
      $padding-y: $padding-x;
      @include clearfix;
      margin: 2em 0;
      padding: $padding-y $padding-x;
      position: relative;
      min-height: 250px;

      header {
        margin-top: 0;
      }

      .featured-image {
        position: relative;
        display: block;
        overflow: hidden;
        height: 15rem;
        margin-top: -$padding-y;
        margin-left: -$padding-x;
        margin-right: -$padding-x;
        margin-bottom: $padding-y;

        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 100%;
          height: auto;

          html:global(.has-object-fit) & {
            width: 100% !important;
            height: 100% !important;
            object-position: center;
            object-fit: cover;
          }
        }
      }

      .entry-footer {
        position: relative;
        padding-bottom: 1.5rem;
        margin-top: 3rem;
      }

      .continue-reading {
        position: absolute;
        right: 0;
        bottom: 0;
      }

      .places {
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
        font-style: italic;
      }

      @media (min-width: 700px) {
        padding-left: 42%;

        .featured-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 40%;
          height: 100%;
          margin: 0;

          img {
            height: 100%;
            width: auto;

            @media (min-width: 1570px) {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }

    &:not(:global(.summary)) {
      .entry-footer {
        // margin-top: 3rem;
      }

      .places {
        margin-top: 4rem;
      }
    }

    &:global(.featured) {
      padding-left: 1em;

      .featured-image {
        @media (min-width: 700px) {
          position: relative;
          width: inherit;
          height: 0;
          padding-bottom: 50%;
          margin-top: -1em;
          margin-left: -1em;
          margin-right: -1em;
          margin-bottom: 2em;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      .entry-content {
        max-height: 30em;
        overflow: hidden;

        @media (min-width: 700px) {
          max-height: 20em;
        }

        p {
          p + & {
            margin-top: 1em;
          }

          &:empty {
            display: none;
          }
        }

        :global(.image-wrap-width) {
          display: none;
        }
      }
    }

    &:global(.loading) {
      .entry-content {
        @keyframes pulse {
          from {
            opacity: 0.8;
          }

          to {
            opacity: 0.4;
          }
        }

        animation-name: pulse;
        animation-duration: 0.8s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in;
      }
    }
  }
}

.fade {
  background: linear-gradient(
    to bottom,
    rgba(#fff, 0) 0%,
    rgba(#fff, 0.8) 50%,
    rgba(#fff, 1) 100%
  );
  position: absolute;
  height: 10em;
  width: 100%;
  left: 0;
  bottom: 3em;
}
