.alert {
  $transition: all 300ms ease;
  overflow: hidden;

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  &:global(.slide-down-enter) {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    border-width: 0;

    &:global(.slide-down-enter-active) {
      transition: $transition;
      max-height: 100px;
      padding-top: $alert-padding;
      padding-bottom: $alert-padding;
      border-width: 1px;
    }
  }

  &:global(.slide-down-leave) {
    max-height: 100px;
    padding-top: $alert-padding;
    padding-bottom: $alert-padding;
    border-width: 1px;

    &:global(.slide-down-leave-active) {
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0;
      border-width: 0;
      transition: $transition;
    }
  }
}
