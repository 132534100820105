@import 'src/styles/variables';
@import 'src/styles/mixins';

ol {
  &.ribbon-links {
    @include list-unstyled;
    margin-top: $sidebar-box-padding-y;
    margin-bottom: -$sidebar-box-padding-y;

    > li {
      margin-left: -$sidebar-box-padding-x;
      margin-right: -$sidebar-box-padding-x;

      & + li {
        margin-top: 1px;
      }

      > a {
        position: relative;
        display: block;
        overflow: hidden;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 110%;
          height: auto;
          filter: blur(20px);
          transition: filter 200ms ease-in, width 200ms ease-in;
        }

        .name {
          position: relative;
          background: linear-gradient(
            to right,
            rgba(#fff, 0.3) 10%,
            rgba(#fff, 0.8) 100%
          );
          padding: 1em $sidebar-box-padding-x;
          color: #fff;
          transition: padding 200ms ease-in, background 200ms ease-in;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 0.25em;
        }

        &:hover {
          text-decoration: none;

          img {
            width: 130%;
            filter: none;
          }

          .name {
            padding: 3em $sidebar-box-padding-x;
            background: linear-gradient(
              to right,
              rgba(#fff, 0.1) 10%,
              rgba(#fff, 0) 100%
            );
          }
        }
      }
    }
  }
}
