@use 'sass:math';

@import 'src/styles/variables';
@import 'src/styles/mixins';

.sidebar-box-wrapper {
  $padding-x: $sidebar-box-padding-x;
  $padding-y: $sidebar-box-padding-y;

  & + .sidebar-box-wrapper {
    margin-top: $gutter-width-y;
  }

  @media (min-width: $two-up-breakpoint) and (max-width: #{$sidebar-breakpoint - math.div(1, 16)}) {
    width: 50%;
    float: left;
    padding: $gutter-width-y * 0.5 $gutter-width-x * 0.5;
    margin-top: 0 !important;
  }

  .sidebar-box {
    @include content-box;
    padding: $padding-y $padding-x;
  }

  .featured-image {
    margin-left: -$padding-x;
    margin-right: -$padding-x;
    margin-top: -$padding-y;

    img {
      width: 100%;
      height: auto;
    }
  }

  .header {
    text-align: center;
    padding: 1rem 0;
  }

  .full-width {
    margin-left: -$padding-x;
    margin-right: -$padding-x;
  }

  &.about {
    h3 {
      text-align: center;
      margin-top: 0.5em;
    }

    .links {
      text-align: center;
      margin-bottom: 0.5em;

      li {
        display: inline-block;

        & + li {
          margin-left: 0.25em;
        }

        a {
          $bg: $link-color;
          display: block;
          width: 1.8em;
          height: 1.8em;
          background-color: $link-color;
          border-radius: 100%;
          padding: 0.4em;
          transition: background-color 200ms ease-in;

          svg {
            width: 100%;
            height: 100%;
            fill: $container-bg;
          }

          &:hover {
            background-color: lighten($link-color, 15%);
          }
        }
      }
    }
  }

  &.instagram {
    display: none;

    @media (min-width: $sidebar-breakpoint) {
      display: block;
    }

    .full-width {
      margin-bottom: -$padding-y;
    }

    .instagram-images {
      @include clearfix;
      margin: -1px -1px 0 0;

      > li {
        width: percentage(math.div(1, 3));
        float: left;
        padding: 1px 1px 0 0;

        &:nth-child(3n) {
          // padding-right: 0;
        }

        &:nth-child(n + 6) {
          // margin-bottom: -1px;
        }
      }
    }
  }
}
