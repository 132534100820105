.embed-container {
  position: relative;
  margin: 2em 0;

  &:global(.has-dimensions) {
    height: 0;
    overflow: hidden;
    max-width: 100%;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
