@import 'src/styles/variables';
@import 'src/styles/mixins';

.comments-title {
  margin-bottom: 20px;
  font-size: 1.25em;
  text-transform: lowercase;
  border-bottom: 2px dotted $color-light-blue;
}

.comments-wrap {
  background-color: lighten($color-light-blue, 30%);
  margin-left: -$post-padding-xs-x;
  margin-right: -$post-padding-xs-x;
  margin-bottom: -$post-padding-xs-y;
  margin-top: $post-padding-xs-y;

  @media (min-width: $breakpoint-xs) {
    margin-left: -$post-padding-x;
    margin-right: -$post-padding-x;
    margin-bottom: -$post-padding-y;
    margin-top: $post-padding-y;
  }

  .comments {
    padding: $post-padding-xs-y $post-padding-xs-x;

    @media (min-width: $breakpoint-xs) {
      padding: $post-padding-y $post-padding-x;
    }
  }
}

.comments {
  p {
    line-height: 1.4em;

    & + p {
      margin-top: 1em;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  :global(.form-control) {
    background-color: $container-bg;
  }

  ol {
    @include list-unstyled;
    margin-bottom: 2.25rem;

    li {
      margin-top: 2.25rem;

      &:first-child {
        margin-top: 0;
      }

      .comment-info {
        @include clearfix;
        position: relative;
        border-bottom: 1px solid #aaa;
        margin-bottom: 0.25rem;

        h2 {
          font-size: 1.3em;
          display: inline-block;
          margin-left: 0.1em;
        }

        .comment-byline {
          float: left;
          margin-bottom: -3px;
        }

        .comment-author {
          float: left;
          margin-bottom: -10px;
        }

        .comment-date {
          color: #808080;
          text-transform: uppercase;
          font-size: 0.9em;
          margin-left: 0.4em;
        }

        .comment-action {
          float: right;
          line-height: 25px;
        }
      }

      .comment-body {
        .reply {
          @include clearfix;
          text-align: right;
        }
      }
    }
  }

  .comment {
    ol {
      &.children {
        @include clearfix;
        margin-top: 5px;
        margin-left: 50px;
        border-left: 1px solid #aaa;

        .comment-info,
        .comment-body,
        .comment-respond {
          padding-left: 0.5em;
        }

        li {
          padding: 0;
        }

        .comment-respond {
        }

        #respond {
          margin-left: 20px;

          textarea {
            &#comment {
              width: 98%;
            }
          }
        }
      }
    }
  }
}

.commentwrap {
  @include clearfix;
}

.comment-reply-link {
  float: right;
  width: 17px;
  height: 17px;
  padding: 5px 10px 3px;
  margin-right: -10px;
  cursor: pointer;
  background: transparent;
  border: 0;
  box-sizing: content-box;

  svg {
    width: 100%;
    transition: fill 0.5s;
    fill: $link-color;
  }

  &:hover {
    background-position: 0 0;

    svg {
      fill: darken($link-color, 25%);
    }
  }

  &:focus {
    outline: 0;
  }
}

.comment-form {
  .comment-notes {
    :global(.required) {
      font-size: 1.5em;
      color: $state-danger-text;
    }
  }

  .form-submit {
    margin-top: 12px;
  }

  label {
    display: block;
    float: left;
    width: 7em;
  }

  :global(.form-text) {
    font-size: 1.25em;
  }

  p {
    margin-top: 1em;
  }
}
