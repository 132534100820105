@import 'src/styles/variables';
@import 'src/styles/mixins';

// div {
//   &:global(.daily-double) {
//     overflow: hidden;
//   }
// }

.image-wrap-width {
  &:global(.aligncenter),
  &:global(.alignleft),
  &:global(.alignright) {
    margin: 2em 0;
  }

  &:global(.aligncenter) {
    display: block;
    margin: 2em auto;
    clear: both;
  }

  &:global(.alignleft) {
    float: left;
  }

  &:global(.alignright) {
    float: right;
  }

  &:global(.size-blog-image) {
    width: 100%;
  }

  &:global(.size-blog-image-tall) {
    width: 67.5%;
  }

  &:global(.size-full) {
    article:global(.post) & {
      max-width: 100%;
    }
  }

  &:global(.size-daily-double),
  &:global(.size-daily-double-square) {
    width: 49.9%;
  }

  div:global(.daily-double) & {
    width: 49.9% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  div:global(.daily-double) a:last-of-type &,
  div:global(.daily-double) > &:last-of-type {
    float: right;
  }

  &:global(.size-daily-trio),
  &:global(.size-daily-trio-square) {
    width: 33.2%;
    margin-top: 0;

    &:global(.aligncenter) {
      clear: none;
    }
  }
}

.image-wrap {
  position: relative;
  width: 100%;
  height: 0;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }
}

@mixin portrait-image-mobile {
  width: 70%;
  height: auto;
  margin-right: auto !important;
  margin-left: auto !important;
}

@media screen and (max-width: 751px) {
  article {
    &:global(.post) {
      .image-wrap-width {
        &:global(.size-full) {
          height: auto;
          max-width: 100vw;

          &:global(.orientation-portrait) {
            @include portrait-image-mobile;
          }
        }
      }
    }
  }

  .image-wrap-width {
    &:global(.size-blog-image) {
      width: 100vw;
    }

    &:global(.size-blog-image-tall) {
      @include portrait-image-mobile;
    }

    &:global(.size-daily-double),
    &:global(.size-daily-double-square) {
      width: 49.88vw;
    }

    &:global(.aligncenter) {
      @include full-width-image;
    }

    &:global(.alignleft) {
      margin-left: -$post-padding-xs-x;

      @media (min-width: $breakpoint-xs) {
        margin-left: -$post-padding-x;
      }
    }

    &:global(.alignright) {
      margin-right: -$post-padding-xs-x;

      @media (min-width: $breakpoint-xs) {
        margin-right: -$post-padding-x;
      }
    }
  }

  // div {
  //   &:global(.daily-double) {
  //     @include full-width-image;
  //   }
  // }
}

@media screen and (max-width: 550px) {
  .image-wrap-width:global(.size-blog-image-tall),
  article:global(.post)
    .image-wrap-width:global(.size-full):global(.orientation-portrait) {
    width: 100vw !important;
    height: auto;
    margin-right: -$post-padding-xs-x !important;
    margin-left: -$post-padding-xs-x !important;

    @media (min-width: $breakpoint-xs) {
      margin-right: -$post-padding-x !important;
      margin-left: -$post-padding-x !important;
    }
  }

  div {
    &:global(.daily-double) {
      .image-wrap-width {
        &,
        &:global(.size-full):global(.orientation-portrait) {
          width: 49.9% !important;
          margin-right: 0 !important;
          margin-left: 0 !important;
        }
      }
    }
  }
}
