@import 'src/styles/variables';

.continents-map {
  width: 100%;
  height: auto;
  max-height: 100%;
  fill: $color-light-blue;
  stroke: $container-bg;
  stroke-width: 50;

  .continent {
    &:global(.linked) {
      cursor: pointer;
    }

    &:global(.hovered) {
      &:global(.linked) {
        fill: darken($color-light-blue, 15%);
      }
    }

    &:global(.visited) {
      fill: $color-dark-blue;

      &:global(.hovered) {
        &:global(.linked) {
          fill: darken($color-dark-blue, 15%);
        }
      }
    }
  }
}
